import { render } from "./addPatients.vue?vue&type=template&id=4d28dc74&scoped=true"
import script from "./addPatients.vue?vue&type=script&lang=js"
export * from "./addPatients.vue?vue&type=script&lang=js"

import "./addPatients.vue?vue&type=style&index=0&id=4d28dc74&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-4d28dc74"
/* hot reload */
if (module.hot) {
  script.__hmrId = "4d28dc74"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4d28dc74', script)) {
    api.reload('4d28dc74', script)
  }
  
  module.hot.accept("./addPatients.vue?vue&type=template&id=4d28dc74&scoped=true", () => {
    api.rerender('4d28dc74', render)
  })

}

script.__file = "src/views/my/addPatients/addPatients.vue"

export default script