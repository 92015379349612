<template>
    <div class="addPatients">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    添加患者
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='contenter'>
            <div class='list'>
                <div class='name'>姓名</div>
                <div class='dec'>
                  <van-field v-model="pname" class='input_text' placeholder="请输入姓名" input-align="right"/>
                </div>
            </div>
          <div class='list'>
            <div class='name'>性别</div>
            <div class='dec'>
              <van-radio-group v-model="checked" direction="horizontal">
                <van-radio name="0">男</van-radio>
                <van-radio name="1">女</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class='list'>
            <div class='name'>证件类型</div>
            <div class='dec'>
              <van-dropdown-menu>
                <van-dropdown-item v-model="cardClassify" :options="cardOptions"/>
              </van-dropdown-menu>
            </div>
          </div>
            <div class='list'>
                <div class='name'>证件号码</div>
                <div class='dec'>
                    <van-field v-model="idcard" class='input_text' placeholder="请输入身份证号" input-align="right"/>
                </div>
            </div>
            <div class='list'>
                <div class='name'>手机号</div>
                <div class='dec'>
                     <van-field v-model="tel" class='input_text' placeholder="请填写手机号码" input-align="right"/>
                </div>
            </div>
            <div class='list_other'>
                <div class='name'>与患者关系</div>
                <div class='dec'>
                    <div v-for='(item,index) in relationshiplist' :key='index' :class='{active:relationship == item}' @click='changerelationship(item)'>{{item}}</div>
                </div>
            </div>
        </div>

        <div class="patient_tip">
            服务中我们会为就诊人<span style="color:red">免费提供平安意外险、人寿保险，请输入真实身份信息</span>，以免投保失败。
        </div>

        <div class='btn_cont mt80'>
            <van-button type="primary" block round color='#00C291' @click='save'>保存</van-button>
        </div>


    </div>
</template>
<script>
import { onMounted, ref, computed,reactive } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from 'vant';
import loginApi from '@axios/login'


export default {
  name: 'addPatients',
  components: {

  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let checked = ref('0');
    let relationship = ref('');
    let pname = ref('');
    let idcard = ref('');
    let tel = ref('');

    let cardOptions = ref([
         {value:-1,text:'请选择证件类型'}
    ]);
    let cardClassify = ref(-1);


    let relationshiplist = ref([
        '本人',
        '爸爸',
        '妈妈',
        '亲戚',
        '朋友',
        '孩子',
        '其他',
    ])

    const changerelationship = (val) => {
        relationship.value = val;
    }



    const getCardList =()=> {

      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'getDicCertificates',
        userid : store.state.uid,
        _from : 'h5',
        source:'h5_users_002'
      }))
      formData.append('encryption',false)

      loginApi.loginAction(formData).then((res)=>{
        res.data.forEach((item,index)=> {
          let obj ={
              text:item.NAME,
              value:item.TYPE
          }
          cardOptions.value.push(obj)
        })
      })
    }
    getCardList()

    const save = () => {
        var cardreg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        var telReg = /^1[345789][0-9]{9}$/gi;
        var otherCard = /^[A-Za-z0-9]+$/;
        if(!pname.value){
            Toast('请输入患者名字');
            return;
        }
        if(cardClassify.value == -1){
          Toast('请选择证件类型');
          return;
        }
        if(!idcard.value){
            Toast('请输入证件号码');
            return;
        }
        console.log(idcard.value)

      // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X

      if(cardClassify.value == 0 && !cardreg.test(idcard.value)) {
        Toast('身份证号格式不正确');
        return;
      }
      if(cardClassify.value > 0 && !otherCard.test(idcard.value)) {
        Toast('证件号码格式不正确');
        return;
      }
      if(!tel.value){
            Toast('请输入电话');
            return;
        }
        if(!telReg.test(tel.value)){
            Toast('电话号格式不正确');
            return;
        }
        if(!relationship.value){
            Toast('请选择与患者关系');
            return;
        }

        var myDate = new Date();
        let age = (myDate.getFullYear() - idcard.value.substring(6, 10)).toString();

        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'savePatientInfo',
            token : store.state.token,
            userid : store.state.uid,
            realname : pname.value,
            sex:checked.value,
            district:cardClassify.value,
            age : age + '',
            mobile :tel.value,
            relationship :relationship.value,
            idCardNo : idcard.value,
            _from : 'h5',
            version : store.state.version,
            newversion : store.state.newversion,
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            router.back();
        })
    }


    return {
        save,
        tel,
        idcard,
        pname,
        changerelationship,
        relationship,
        relationshiplist,
        cardClassify,
        cardOptions,
      checked
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
     ::v-deep(.van-dropdown-menu__bar){
        height: 30px;
       box-shadow: none;
     }
     ::v-deep( .van-dropdown-item__option--active){
        color:#00C291;
     }
     ::v-deep(.van-dropdown-menu__title--active){
       color:#00C291;
     }
     ::v-deep(.van-dropdown-item__option--active .van-dropdown-item__icon){
       color:#00C291;
     }
     ::v-deep(.van-dropdown-item__content){
       max-height: 60%;
     }
     ::v-deep(.van-radio__icon--checked .van-icon){
       background-color: #00C291;
       border-color: #00C291;
     }


    .patient_tip {
        padding: 25px 30px 0;
        font-size: 25px;
    }
    .input_text{
        width: 400px;
        text-align: right;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #979797;
        padding:0!important;

    }
    .addPatients{
        height: 100%;
        background:#faf9f9;
    }
    .contenter{
        background: #fff;
        padding:0 45px;
        box-shadow: 0px 2px 2px 2px rgba(230, 230, 230, 0.2), 0px 0px 2px 0px rgba(178, 178, 178, 0.12);
        .list_other{
            padding:30px 21px;
            font-size: 34px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            border-bottom:1px solid #EDEDED;
            .dec{
                font-size: 30px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #979797;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                margin-top:30px;
                .active{
                    background:#00C291;
                    border:1px solid #00C291;
                    color:#fff;
                }
                &>div{
                    height: 42px;
                    line-height: 42px;
                    border-radius: 27px;
                    border: 1px solid #919090;
                    padding:0 20px;
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    margin-bottom: 20px;
                    margin-right:20px;
                }
                .arrow{
                    margin-left:20px;
                }
            }
        }

        .list{
            padding:30px 21px;
            font-size: 34px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            display: flex;
            justify-content: space-between;
            border-bottom:1px solid #EDEDED;
            .dec{
                font-size: 30px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #979797;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .arrow{
                    margin-left:20px;
                }
            }
        }
    }

</style>
